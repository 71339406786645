@font-face {
  font-family: "Gotham";
  src: url("/public/fonts/GothamPro-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: black;
}

@font-face {
  font-family: "Gotham";
  src: url("/public/fonts/GothamPro-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: bold;
}

@font-face {
  font-family: "Gotham";
  src: url("/public/fonts/GothamPro-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: medium;
}

@font-face {
  font-family: "Gotham";
  src: url("/public/fonts/GothamPro.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
  margin: 0;
  font-weight: 300;
}

body {
  font-family: "Gotham", sans-serif;
  margin: 0;
  padding: 0;
  font-weight: normal;
  color: #5e5e5e;
  overflow-x: hidden;
}

p,
ul,
li {
  font-size: 18px;
  line-height: 28px;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}
